import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { FC } from "react";

import "../styles/components/defaultPopup.css";
import { ButtonProps, CustomButton } from "./customButton";

export const DefaultPopup: FC<{
  isOpen: boolean;
  handleClose: () => void;
  title?: string;
  description?: string;
  acceptBtnProps: ButtonProps;
  cancelBtnProps: ButtonProps;
}> = ({
  isOpen,
  handleClose,
  title,
  description,
  cancelBtnProps,
  acceptBtnProps,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      transition
      className="data-[closed]:opacity-0 default-popup"
    >
      <DialogPanel className="default-popup-panel">
        {title && (
          <DialogTitle className="default-popup-title">{title}</DialogTitle>
        )}
        {description && (
          <Description className="default-popup-description">
            {description}
          </Description>
        )}
        <div className="default-popup-btns">
          <CustomButton onClick={handleClose} {...cancelBtnProps}>
            {cancelBtnProps.children}
          </CustomButton>
          <CustomButton {...acceptBtnProps}>
            {acceptBtnProps.children}
          </CustomButton>
        </div>
      </DialogPanel>
    </Dialog>
  );
};
